import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'

import {setLabel, setColor, modifierStack} from '@helpers/openlayers/styles'
import {cultivationToColor} from '@frs/helpers/colors'
import {getCropUsageTranslations} from '@frs/helpers/crops'

import {polygonBase, stripedPolygonBase, stripedPolygonBaseWithoutText} from './base'

// TODO resolve via localization if possible
const typeNames = {
  main: 'Hauptfrucht',
  secondary: 'Zweitfrucht',
  catch: 'Zwischenfrucht',
  dual: 'ZKNS',
  eco: 'ÖVF'
}

const cultivationToLabel = (type, cropUsage) => {
  switch (type) {
  case 'main':
  case 'secondary': {
    return cropUsage ? getCropUsageTranslations(cropUsage.id).name : typeNames[type]
  }
  default:
    return typeNames[type]
  }
}

export const activeCultivation = modifierStack(polygonBase, [
  setLabel(feature => feature.get('name')),
  setColor(feature => {
    const {type, ecoTypeId, cropUsage} = feature.getProperties()
    return cultivationToColor({type, ecoTypeId}, cropUsage)
  })
])

export const cultivation = modifierStack(stripedPolygonBase, [
  setLabel(feature => {
    const {type, cropUsage} = feature.getProperties()
    return cultivationToLabel(type, cropUsage)
  }),
  setColor(feature => {
    const {type, ecoTypeId, cropUsage} = feature.getProperties()
    return cultivationToColor({type, ecoTypeId}, cropUsage)
  })
])

export const cultivationWithoutText = modifierStack(stripedPolygonBaseWithoutText, [
  setColor(feature => {
    const {type, ecoTypeId, color} = feature.getProperties()
    return {
      fill: cultivationToColor({type, ecoTypeId}, null, color),
      stroke: 'rgb(255, 0, 0)'
    }
  })
])

export const cultivationWithCode = modifierStack(stripedPolygonBase, [
  setLabel(feature => {
    const props = feature.getProperties()
    return props.fieldCodes
  }),
  setColor(feature => {
    const {type, ecoTypeId, color} = feature.getProperties()
    const cultivationColor = cultivationToColor({type, ecoTypeId}, null, color)
    return {
      fill: cultivationColor,
      stroke: 'rgb(255, 0, 0)',
      textColor: cultivationColor
    }
  })
])

export const cultivationFieldBorder = [new Style({
  stroke: new Stroke({
    color: 'rgb(180, 180, 180)',
    width: 1,
    lineDash: [10, 5]
  })
})]

export const fieldBorderCultivationSelected = new Style({
  stroke: new Stroke({
    color: 'rgb(255, 255, 255)',
    width: 3
  })
})
