import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'

import {setColor, modifierStack} from '@helpers/openlayers/styles'

export const fieldBorderSuggestionBase = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.25)'
  }),
  stroke: new Stroke({
    color: 'rgba(255, 255, 255, 0.5)',
    width: 1
  })
})

export const fieldBorderSuggestion = modifierStack(fieldBorderSuggestionBase, [
  setColor(feature => feature.get('fclass') === 'farm' ? 'rgba(255, 255, 255)' : 'rgba(128, 128, 128)', 0.5)
])

export const roads = new Style({
  stroke: new Stroke({
    color: 'rgb(99, 165, 182)',
    width: 2
  })
})
