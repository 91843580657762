export function cultivationToColor ({type, ecoTypeId}, cropUsage = null, color = null) {
  let displayColor
  switch (type) {
  case 'main':
  case 'secondary':
    if (cropUsage) {
      displayColor = cropUsage.color
    } else if (color) {
      displayColor = color
    }
    break
  case 'eco': {
    switch (ecoTypeId) {
    case 'Brache': {
      displayColor = '#4a91b1'
      break
    }
    default: {
      displayColor = '#6c9db3'
      break
    }
    }
    break
  }
  case 'catch': {
    displayColor = '#302f69'
    break
  }
  default: {
    displayColor = '#ff0000'
    break
  }
  }
  return displayColor || '#3b3b3b'
}
