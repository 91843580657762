import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'
import Circle from 'ol/style/Circle'

const makeUniversalStyle = ({stroke, fill, radius = 5}) => new Style({
  image: new Circle({
    radius,
    stroke,
    fill
  }),
  stroke,
  fill
})

const defaultSelectionStyle = makeUniversalStyle({
  stroke: new Stroke({
    color: 'white',
    width: 4
  })
})

const selectionMarkerStyle = new Style({
  image: new Circle({
    radius: 20,
    stroke: new Stroke({color: 'white', width: 2})
  }),
  geometry: feature => {
    const geometry = feature.getGeometry()
    if (geometry.getType() === 'Polygon') {
      return geometry.getInteriorPoint()
    }
  }
})

export const selectionStyle = (feature, resolution) => {
  if (resolution > 100) {
    return [defaultSelectionStyle, selectionMarkerStyle]
  } else {
    return defaultSelectionStyle
  }
}

export const hoverStyle = makeUniversalStyle({
  stroke: new Stroke({
    color: 'white',
    width: 1
  }),
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.1)'
  })
})

export const selectedPoint = new Style({
  image: new Circle({
    radius: 6,
    fill: new Fill({
      color: '#ffa500'
    }),
    stroke: new Stroke({color: '#875900', width: 3})
  })
})
