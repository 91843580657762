import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import ColorHash from 'color-hash'

import {setLabel, setColor, modifierStack} from '@helpers/openlayers/styles'
import {withAlpha} from '@helpers/color'
import {polygonBase} from './base'

const hash = new ColorHash()

export const entityImportPreview = modifierStack(polygonBase, [
  setLabel(feature => {
    const {name, colorProperty} = feature.getProperties()

    if (!colorProperty) return name

    const colorKey = feature.get(colorProperty)

    return colorKey
      ? `${name} (${colorKey})`
      : name
  }),
  setColor(feature => {
    const {selected, colorProperty} = feature.getProperties()

    const colorKey = feature.get(colorProperty)
    const color = colorKey ? hash.hex(colorKey) : '#bbb'

    return {stroke: color, fill: selected ? withAlpha(color, 0.75) : 'rgba(0, 0, 0, 0)'}
  })
])

export const entityImportPreviewHover = new Style({
  stroke: new Stroke({
    color: '#ffffff',
    width: 2
  })
})
