import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Circle from 'ol/style/Circle'
import Stroke from 'ol/style/Stroke'

import {modifierStack, setColor, setLabel} from '@helpers/openlayers/styles'
import {desaturate} from '@helpers/color'
import {colorFunctions} from '@helpers/map-color-schemes'
import {zoneBase} from './base'

const minimalZoneColors = [
  '#0050d7',
  '#1bc1d1',
  '#35d11b',
  '#d1b41b',
  '#bb1303'
]

export const zonemap = modifierStack(zoneBase, [
  setColor((feature) => {
    // TODO rename `zone` to `zoneNumber` or `number`
    const {zone, zoneCount} = feature.getProperties()

    return colorFunctions.agrosat(zone, zoneCount)
  }, 0.75),
  setLabel((feature) => {
    const {placeholder, zone, e_vari: variation} = feature.getProperties()

    if (placeholder) return placeholder

    return variation ? `${zone} ${variation}` : `${zone}`
  })
])

export const agrosatApplicationMap = modifierStack(zoneBase, [
  setColor((feature) => {
    // TODO rename `zone` to `zoneNumber` or `number`
    const {zone, zoneCount} = feature.getProperties()

    return colorFunctions.agrosat(zone, zoneCount)
  }, 0.75),
  setLabel((feature) => {
    const {displayValue} = feature.getProperties()
    return displayValue
  })
])
const minimalBase = new Style({
  fill: new Fill({
    color: 'transparent'
  })
})

const mutedZoneColors = minimalZoneColors.map(color => desaturate(color, 0.6))

export const zonemapMinimal = modifierStack(minimalBase, [
  setColor(feature => mutedZoneColors[feature.get('zone')] || '#dddddd', 0.6)
])

export const error = [new Style({
  image: new Circle({
    fill: new Fill({
      color: 'rgba(255, 127, 0, 0.3)'
    }),
    stroke: new Stroke({
      color: 'rgba(255, 0, 0, 1)',
      width: 5
    }),
    radius: 20
  })
})]
