import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'

import {modifierStack, setLabel, setColor} from '@helpers/openlayers/styles'
import {stripes} from 'src/js/helpers/openlayers/patterns'

import {cultivationToColor} from '@frs/helpers/colors'

import {polygonBase, stripedPolygonBase} from './base'

const actionTypeColors = {
  'seeding': 'rgb(145, 210, 80)',
  'irrigation': 'rgb(150, 180, 215)',
  'tillage': 'rgb(200, 95, 10)',
  'fertilization': 'rgb(90, 115, 45)',
  'harvest': 'rgb(255, 190, 0)',
  'protection': 'rgb(245, 50, 15)',
  'maintenance': 'rgb(245, 250, 85)',
  'logistics': 'rgb(125, 125, 125)'
}

const actionToColor = ({type}) => {
  const color = actionTypeColors[type] || 'rgb(138, 138, 138)'
  return {fill: stripes(color, 'transparent'), stroke: color}
}

export const actionCultivationSelection = modifierStack(polygonBase, [
  setLabel(feature => ''),
  setColor(feature => {
    const {cultivation, cropUsage} = feature.getProperties()
    if (cultivation) {
      const {type, ecoTypeId} = cultivation
      return cultivationToColor({type, ecoTypeId}, cropUsage)
    } else {
      return 'rgb(138, 138, 138)'
    }
  })
])

export const actionCultivationSelectionSelected = modifierStack(stripedPolygonBase, [
  setLabel(feature => ''),
  setColor(feature => ({stroke: 'rgba(255, 255, 255, 0.5)', fill: stripes('rgba(255, 255, 255, 0.4)', 'transparent')}))
])

export const actionCultivationSelectionHover = modifierStack(polygonBase, [
  setLabel(feature => ''),
  setColor(feature => ({stroke: 'rgba(255, 255, 255, 1)', fill: 'rgba(255, 255, 255, 0.4)'}))
])

export const actionCultivationSelectionCutOff = new Style({
  fill: new Fill({
    color: stripes('rgba(255, 0, 255, 0.3)', 'transparent')
  })
})

export const action = modifierStack(stripedPolygonBase, [
  setLabel(feature => {
    const {name} = feature.getProperties()
    return name || ''
  }),
  setColor(feature => {
    const {type} = feature.getProperties()
    return actionToColor({type})
  })
])
