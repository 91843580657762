import {modifierStack, setColor, setLabel} from '@helpers/openlayers/styles'
import {zoneBase} from '@frs/map-styles/base'
import {colorFunctions} from '@helpers/map-color-schemes'

import colors from './colors'
import {makeProbingGeometryStyle, classificationResultStyle} from './probing-geometries'

const selectPlanningColor = ({isActive, isNew, markedForDeletion}) => isNew
  ? markedForDeletion
    ? null
    : 'new'
  : markedForDeletion
    ? 'deleted'
    : isActive
      ? 'active'
      : 'inactive'

export const planningPoint = makeProbingGeometryStyle(selectPlanningColor)

const selectOrderPointPreviewColor = ({active}) => active
  ? 'active'
  : 'inactive'

export const orderPointPreview = makeProbingGeometryStyle(selectOrderPointPreviewColor)

const selectResultPreviewColor = ({isCompleted, isSelected}) => isSelected
  ? 'active'
  : isCompleted
    ? 'hasResult'
    : 'open'

export const probingResultPreview = makeProbingGeometryStyle(selectResultPreviewColor)

export const nutrientClassification = classificationResultStyle

export const nutrientMap = modifierStack(zoneBase, [
  setColor(feature => {
    const {placeholder, contentClass} = feature.getProperties()

    if (placeholder) return '#c1c1c1'

    return colors.classification[contentClass] || '#ff00ff'
  }, 0.75),
  setLabel(feature => {
    const {placeholder, id} = feature.getProperties()

    return placeholder || `${id}`
  })
])

export const nutrientApplicationMap = modifierStack(zoneBase, [
  setColor(feature => {
    // TODO rename `type` to `nutrient`
    const {value, type} = feature.getProperties()

    return colorFunctions.lufa(value, type)
  }),
  setLabel(feature => {
    const {id, verboseLabel, displayValue} = feature.getProperties()
    return verboseLabel
      ? `Zone ${id} (${displayValue} kg/ha)`
      : `Zone ${id}`
  })
])

export const plannedYield = modifierStack(zoneBase, [
  setColor(feature => feature.get('plannedYield') ? '#008000' : '#777777', 0.75),
  setLabel(feature => feature.get('plannedYield') || '')
])
