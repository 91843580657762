import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'
import Circle from 'ol/style/Circle'
import Text from 'ol/style/Text'
import Point from 'ol/geom/Point'
import MultiPoint from 'ol/geom/MultiPoint'
import {add} from 'ol/coordinate'

import {setLabel} from '@helpers/openlayers/styles'
import {withAlpha, readableTextColor} from '@helpers/color'
import {map} from '@helpers/objects'

import colors from './colors'

const makePointStyle = color => new Style({
  image: new Circle({
    radius: 5,
    stroke: new Stroke({color: '#000000', width: 2}),
    fill: new Fill({color: withAlpha(color, 0.85)})
  }),
  text: new Text({
    textAlign: 'left',
    textBaseline: 'middle',
    text: '1 (placeholder)',
    font: '14px CalibreWeb',
    fill: new Fill({
      color: readableTextColor(color)
    }),
    stroke: new Stroke({
      color: withAlpha(color, 1),
      width: 3
    }),
    offsetX: 8,
    overflow: true
  }),
  geometry (feature) {
    const geometry = feature.getGeometry()
    if (geometry.getType() === 'LineString') {
      return new Point(geometry.getCoordinateAt(0.5))
    }
    return geometry
  }
})

const makeLineStyle = color => new Style({
  stroke: new Stroke({
    color: withAlpha(color, 1),
    width: 3
  })
})

const makeVertexStyle = color => new Style({
  image: new Circle({
    radius: 2,
    fill: new Fill({color: withAlpha(color, 1)})
  }),
  geometry (feature) {
    const geometry = feature.getGeometry()
    if (geometry.getType() === 'LineString') {
      return new MultiPoint(geometry.getCoordinates())
    }
    return null
  }
})

const makeZentroidStyle = color => new Style({
  image: new Circle({
    radius: 7,
    stroke: new Stroke({color: withAlpha(color, 1)}),
    fill: new Fill({color: withAlpha(color, 0.05)})
  }),
  text: new Text({
    textAlign: 'left',
    textBaseline: 'middle',
    text: 'Z',
    font: '10px CalibreWeb',
    fill: new Fill({
      color: readableTextColor(color)
    }),
    stroke: new Stroke({
      color: withAlpha(color, 1),
      width: 3
    }),
    offsetX: 10,
    overflow: true
  }),
  geometry (feature) {
    const geometry = feature.getGeometry()
    if (geometry.getType() === 'LineString' && feature.get('showZentroid')) {
      return new Point(geometry.getCoordinates().reduce((sum, val) => add(sum, val)).map(s => s / geometry.getCoordinates().length))
    }
    return null
  }
})

export const makePointLineBaseStyle = color => ({
  point: makePointStyle(color),
  line: makeLineStyle(color),
  vertex: makeVertexStyle(color),
  zentroid: makeZentroidStyle(color)
})

const probingGeometryBaseStyles = map(colors.probingGeometry, makePointLineBaseStyle)

const setProbingGeometryLabel = setLabel(feature => {
  const {label, sampleId, number} = feature.getProperties()

  return (label || sampleId || '') + (number !== undefined ? ` (${number})` : '')
})

export const makeProbingGeometryStyle = colorSelectorFunc => (feature, resolution) => {
  const styles = probingGeometryBaseStyles[colorSelectorFunc(feature.getProperties())]

  if (!styles) return null

  const {line, vertex, point} = styles || {}
  setProbingGeometryLabel(point, feature, resolution)

  return [line, vertex, point]
}

const classificationResultBaseStyles = map(colors.classification, makePointLineBaseStyle)

const setClassificationLabel = setLabel(feature => feature.get('value'))

export const classificationResultStyle = (feature, resolution) => {
  const {line, vertex, point, zentroid} = classificationResultBaseStyles[feature.get('value')]

  setClassificationLabel(point, feature, resolution)

  return [line, vertex, point, zentroid]
}
