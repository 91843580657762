import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'

import {setLabel, modifierStack} from '@helpers/openlayers/styles'
import {polygonClusterBase, border, borderBase} from './base'

export const field = modifierStack(border, [
  setLabel(feature => feature.get('name') || '')
])

export const fieldWithCode = modifierStack(border, [
  setLabel(feature => feature.get('code') || '')
])

// TODO: might be obsolete
export const fieldBorder = modifierStack(borderBase, [
  setLabel(feature => feature.get('name') || '')
])

export const fieldCluster = (feature, resolution) => {
  if (resolution < 100) {
    return []
  } else {
    polygonClusterBase.getText().setText(feature.get('features').length.toString())
    const normalizedResolution = (Math.min(500, resolution) - 100) / 400
    const opacity = (0.2 + normalizedResolution * 0.6).toFixed(2)
    polygonClusterBase.getImage().getFill().setColor(`rgba(255, 130, 40, ${opacity})`)
    polygonClusterBase.getImage().setRadius(15) // NOTE necessary because of ol issue #5775, cached rendering of ol.Image classes, circle only re-renders on radius change

    // NOTE size calculation would need screenspace coordinates, can't access map object here for .getPixelFromCoordinate
    // const coordinates = feature.get('features').map(x => x.getGeometry().getCoordinates()).reduce((combined, coords) => combined.concat(coords), [])
    // const [minx, miny, maxx, maxy] = ol.extent.boundingExtent(coordinates)
    // const radius = ((maxx - minx) + (maxy - miny)) / 4
    // polygonClusterBase.getImage().setRadius(Math.max(10, radius))
    return polygonClusterBase
  }
}

export const fieldBorderSelected = new Style({
  stroke: new Stroke({
    color: 'rgb(255, 255, 255)',
    width: 4
  })
  // fill: new Fill({
  //   color: 'rgba(255, 255, 255, 0.3)'
  // })
})
